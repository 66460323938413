.container {
  /* background-color: #878E88; */
  background-image: url('../../../public/backgrounds/Noise/Grey.png');
  display: flex;
  width: 100vw;
  max-width: 100vw;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  
  /* height of viewport - header and footer */
  min-height: calc(100vh - 61px);

  color: #dd4b39;
}

.socialsFlexBox {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.socialsItem {
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;

  color: #dd4b39;
  
  border: 5px solid #3b5998;
  float: left;
  transition: all 1.0s ease;
}

.socialsItem i {
  padding-top: 20px;
}

.linkedIn:hover {
  border: 5px solid #a0ced9;
  box-shadow: 0 0 15px #a0ced9;
  transition: all 1.0s ease;
}

.github:hover {
  border: 5px solid #dd4b39;
  box-shadow: 0 0 15px #dd4b39;
  transition: all 1.0s ease;
}

.email:hover {
  border: 5px solid #F7CB15;
  box-shadow: 0 0 15px #F7CB15;
  transition: all 1.0s ease;
}