.card {
  min-height: calc(70vh - 61px);
  width: 100vw;
  padding-bottom: 30px;

  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
