.App {
  text-align: center;
  background-color: lightgrey;
  height: 100vh;
  font-family: 'SUSE';
}

.pages {
  max-width: 1400px;
  margin: 0;
}

header {
  /* height: 38px; */
}

.footer {
  height: 23px;
}

@font-face {
  font-family: 'SUSE';
  src: url(./Fonts/SUSE/SUSE-VariableFont_wght.ttf) format('truetype-variations');
}