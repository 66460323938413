.footer {
  color: grey;
  background-color: #000;
  font-size: 12px;
  user-select: none;
  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.footerElement {
  margin-right: 20px;
  padding: 5px;
}