.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skillsIcon {
  width: 70px;
  height: 70px;
}

@media only screen and (max-width: 500px) {
  .skillsIcon {
    width: 30px;
    height: 30px;
  }
}