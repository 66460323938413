.headshot {
  margin-top: 3vh;
  border: 2px solid black;
  height: 70vh;  
}

.otherInfo {
  margin-top: 30px;
}

.otherInfo a {
  font-weight: 500;
  background-color: #ee7b63c5;
  color: black;
  text-decoration: none;
}

.otherInfo a:hover {
  background-color: #ee7b63c5;
}

@media only screen and (max-width: 500px) {

  .headshot {
    height: 50vh;
  }
  
}