header {
  background-image: url('../../../public/backgrounds/Noise/Teal.png');    
}

.menuContainer {
  display: flex;
  
}

/* Menu item, Link from router dom sets an anchor tag. */
a {
  text-decoration: none;
}
a .menuItem {
  color: #F7CB15;
  font-size: 16px;

  /* black outline  */
  text-shadow:
  -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000,
  1px 1px 0 #000;
}

.menuLeft {
  padding: 10px 20px;
  width: 15vw;
  margin-right: 40vw;
  
  /* TEMP colours for clarity when designing */
  /* background: black; */
}

.menuLeft .menuItem {
    /* TEMP colours for clarity when designing */
    /* background: teal; */
}

.menuRight {
  padding: 10px 20px;
  width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;

  /* TEMP colours for clarity when designing */
  /* background: blue; */
}

.menuRight .menuItem {


  /* TEMP colours for clarity when designing */
  /* background: teal; */
}