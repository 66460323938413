.notFoundContainer {
  background-image: url('../../Img/backgrounds/darkGrey.png');
  background-color: #878E88;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  
  /* height of viewport - header and footer */
  min-height: calc(100vh - 61px);
}

.funDiv {
  padding-top: 38px;
  font-weight: 500;
}

.kerrigan {
  margin-top: 3vh;
  border: 2px solid black;
  height: 70vh;  
}