.aboutCard {
  background-color: #fcf5c7;
  width: 100vw;  
  display: block;

}

.division{
  padding-top: 8px;
  margin-left: 5vw;
  margin-right: 5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.cardLeft {
  width: 40vw;
  height: 500px;
  background-color: red;
}

.cardRight {
  width: 50vw;
  height: 500px;
  background-color: blue;
}

@media only screen and (max-width:720px) {
  .cardLeft {
    width: 100vw;

  }
  .cardRight {
    width: 100vw;
  }

}