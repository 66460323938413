/* .container {
  background-color: #76bed0;
} */



.homeContainer {
  width: 100vw;
  min-height: calc(100vh - 61px);
  display: flex;
  flex-direction: row;
  
}

.homeContainer a {
  text-decoration: none;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid ;
  border-radius: 5px;
}

.homeContainer a:hover {
  border: 1px solid #a0ced9;
  box-shadow: 0 0 8px #a0ced9;
  transition: all 1.0s ease;
}

.homeContainer a:visited {
  color: black;
  text-decoration: none;
}

/* Left Side css */
.homeLeft {
  background-color: #008080;
  background-image: url('../../Img/backgrounds/teal.png');
  width: 40vw;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
}

.homeLeft span {
  max-width: 500px;
}

.homeLeft a:hover {
  background-color: rgb(20, 172, 172);
}

/* Right side css */
.homeRight {
  background-color: #F55D3E;
  background-image: url('../../Img/backgrounds/orange.png');
  width: 60vw;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding-bottom: 10px;
}


@media only screen and (max-width:1325px) {
  .homeContainer{
    flex-direction: column;
  }
  
  .homeLeft {
    width: 100vw;
    min-height: 400px;
  }
  .homeRight {
    width: 100vw;
    padding-bottom: 20px;
  }

}