.stackList {
  box-sizing: border-box;
  border: solid 2px black;
  border-radius: 15px;
  padding: 5px;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5em;
  padding: 0;
}

.slash {
 margin-left: -2.2em;
 margin-right: -2.2em;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.list {
  width: 30rem;
  height: 10rem;
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 20px 15px 20px 15px ;
  align-items: center;
  justify-content: center;
}

.inactive {
  display: none
}

.button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #1A1A1A;
  cursor: pointer;
  display: inline-block;
  /* font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 120px;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button:disabled {
  pointer-events: none;
}

.button:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: none;
  transform: translateY(0);
}

.button.active {
  background-color: black;
  color: #fff;
}


@media only screen and (max-width: 500px ){
  .stackList{
    max-width: 360px;
  }

  .list {
    height: 6rem;
  }

  .button {
    font-size: 12px;
    padding: 12px 16px;
    max-width: 50px;
  }

  .buttons {
    flex-wrap: wrap;
  }
}