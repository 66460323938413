.generalCard h3 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.generalCard {
  background-color: #ffc09f;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.info {
  width: 55vw;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.generalInfo {
  margin-top: 20px
}

.techStack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex-wrap: wrap;
  
}

.info span {
  gap: 100px;
}



.nonTechnicalInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Expanding on click stuff */
.generalCard H2 {
  background-color: #a0ced9;
  width: 40vw;
  cursor:pointer;

  margin-top: 30px;
  padding: 10px;
  border: 2px solid black;
}

.generalCard h3 {
  background-color: #a0ced9;
  width: 20vw;
  cursor:pointer;

  margin-top: 30px;
  padding: 10px;
  border: 2px solid black;

}

.generalCard p {
  margin: 0 ;
  width: 50vw;
}

.visible {
  opacity: 1;
  transition: opacity 1s;
  
}

.hidden {
  display: none;
  opacity: 0;
}
